<template>
  <div>
    <v-layout wrap style="font-family: poppinsregular">
      <v-flex lg12 xl12>
        <v-snackbar
          v-model="showSnackBar"
          color="#FDCE48"
          right
          :timeout="timeout"
        >
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: #000">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: #000">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <v-layout wrap justify-center py-2>
          <v-flex xs12 text-left px-2>
            <span class="itemHeading">Edit Package</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12 sm6 md4 pa-2>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="packageRate.fromDate"
                  label="Please Select the Start Date"
                  readonly
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="packageRate.fromDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md4 pa-2>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="packageRate.toDate"
                  label="Please Select the End Date"
                  outlined
                  dense
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="packageRate.toDate"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
        <v-layout wrap v-if="details.isProgram == true" justify-start>
          <v-flex xs12>
            <v-layout wrap v-if="details.isBasedOnSeat == false">
              <v-flex text-left xs10 md3>
                <span style="font-family: poppinssemibold; font-size: 14px"
                  >Does the program have fixed rate?

                  {{ details.isBasedOnSeat }}
                </span>
              </v-flex>
              <v-flex xs2 md1>
                <v-checkbox v-model="packageRate.isFixedrate"></v-checkbox>
              </v-flex>
              <v-flex xs12 pt-1 v-if="packageRate.isFixedrate">
                <v-layout justify-center> </v-layout>
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs12 md3 text-left>
                      <span class="tableSide"> Fixed Rate (Rs.) </span>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.fixedRate"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout v-if="details.isProgram == true" wrap justify-start>
          <v-flex xs12 md4 text-left>
            <span style="font-family: poppinssemibold; font-size: 14px"
              >Is the program based on seat number?</span
            >
          </v-flex>
          <v-flex text-left xs2 md1>
            <v-checkbox v-model="packageRate.isBasedOnSeat"></v-checkbox>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex text-left pb-4>
            <span style="font-family: poppinssemibold; font-size: 14px"
              >Please specify package rate for different guests (in Rs.)
              <sup>*</sup></span
            >
          </v-flex>
          <v-flex xs12 hidden-sm-and-down>
            <v-layout justify-center>
              <v-flex xs2 md3> </v-flex>
              <v-flex md3 text-left>
                <span class="tableHeading"> Weekdays </span>
              </v-flex>

              <v-flex md3 text-left>
                <span class="tableHeading"> Weekends And Holidays</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify- pt-2 v-if="programData.bookingAvailability">
              <v-flex
                xs12
                v-if="programData.bookingAvailability.indian"
                text-left
              >
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Indian </span>
                    </v-flex>
                    <v-flex md3 text-left>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-left>
                      <v-text-field
                        class="tableValue"
                        v-model="packageRate.holidays.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                pt-1
                v-if="programData.bookingAvailability.foreigner"
                text-left
              >
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Foreigner </span>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue" type="number"
                        v-model="packageRate.holidays.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex> -->
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                pt-1
                v-if="programData.bookingAvailability.children"
                text-left
              >
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Children </span>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>



              <v-flex
                xs12
                pt-1
               
                text-left
              >
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Foreign Student
 </span>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.foreignStudent"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.foreignStudent"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-layout wrap justify-start>
                <v-flex text-left xs12 md3>
                  <span style="font-family: poppinssemibold; font-size: 14px"
                    >Do you want to add extra per head</span
                  >
                </v-flex>
                <v-flex xs2 md1>
                  <v-checkbox
                    v-model="packageRate.isExtraPerHeadAvailable"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-flex
                xs12
                pt-1
                v-if="packageRate.isExtraPerHeadAvailable"
                text-left
              >
                <v-layout justify-center>
                  <v-flex xs2 md3> </v-flex>
                  <v-flex
                    md3
                    text-left
                    v-if="programData.bookingAvailability.indian"
                  >
                    <span class="tableHeading"> Indian </span>
                  </v-flex>
                  <v-flex
                    md3
                    text-left
                    v-if="programData.bookingAvailability.foreigner"
                  >
                    <span class="tableHeading">Foreigner</span>
                  </v-flex>
                  <v-flex
                    md3
                    text-left
                    v-if="programData.bookingAvailability.children"
                  >
                    <span class="tableHeading">Children</span>
                  </v-flex>
                  <v-flex
                    md3
                    text-left
                    v-if="programData.bookingAvailability.foreigneChildren"
                  >
                    <span class="tableHeading">Foreign Children</span>
                  </v-flex>
                </v-layout>
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex text-left>
                      <span class="tableSide"> Extra per head </span>
                    </v-flex>
                    <v-flex
                      text-center
                      v-if="programData.bookingAvailability.indian"
                    >
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      text-center
                      v-if="programData.bookingAvailability.foreigner"
                    >
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      text-center
                      v-if="programData.bookingAvailability.children"
                    >
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      text-center
                      v-if="programData.bookingAvailability.foreigneChildren"
                    >
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.foreignStudent"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-if="details.isProgram == true" wrap hidden-md-and-up>
          <v-flex xs12>
            <v-card auto color="#68D389">
              <v-layout wrap justify-start pa-6>
                <v-flex xs12 text-left>
                  <h4 style="color: #ffffff; font-family: poppinssemibold">
                    Vehicle Details
                    <span> </span>
                  </h4>
                </v-flex>
                <v-flex xs12 pt-3 text-left> </v-flex>
                <v-flex xs2>
                  <v-btn color="#68D389" @click="addValues(), (dialog2 = true)">
                    <span>
                      <h4 style="color: #ffffff; font-family: poppinssemibold">
                        Add Vehicle
                      </h4></span
                    >
                    <v-icon color="white">mdi-plus</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-layout
              pl-2
              v-for="(item, i) in packagedata"
              :key="i"
              wrap
              justify-start
            >
              <v-flex pa-4 xs12 align-self-center>
                <span class="tableSide" style="color: #606060"
                  >Vehicle Number</span
                >

                <v-text-field solo v-model="item.vehicleNumber"></v-text-field>
              </v-flex>
              <v-flex xs12 pa-4 text-left>
                <span class="tableSide" style="color: #606060">
                  Driver Name
                </span>

                <v-text-field solo v-model="item.vehicleDriver"></v-text-field>
              </v-flex>
              <v-flex pa-4 xs12 text-left>
                <span class="tableSide" style="color: #606060">
                  Seat Capacity
                </span>

                <v-text-field solo v-model="item.seatCapacity"></v-text-field>
              </v-flex>
              <v-flex xs12 pa-4 text-left>
                <span class="tableSide" style="color: #606060"
                  >Driver Phone Number
                </span>
                <v-text-field solo v-model="item.vehiclePhone"></v-text-field>
              </v-flex>

              <v-flex xs12 pt-6>
                <v-btn
                  dark
                  style="background-color: #68d389"
                  dense
                  @click="packagedata.splice(i, 1)"
                >
                  Remove
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-if="details.isProgram == true" wrap hidden-sm-and-down>
          <v-flex xs12>
            <v-card auto color="#68D389">
              <v-layout wrap justify-start pa-6>
                <v-flex md3 text-left>
                  <h4 style="color: #ffffff; font-family: poppinssemibold">
                    Vehicle Details
                    <span> </span>
                  </h4>
                </v-flex>
                <v-flex md6 text-right> </v-flex>
                <v-flex xs2>
                  <v-btn color="#68D389" @click="addValues(), (dialog2 = true)">
                    <span>
                      <h4 style="color: #ffffff; font-family: poppinssemibold">
                        Add Vehicle
                      </h4>
                    </span>
                    <v-icon color="white">mdi-plus</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex xs12 align-self-center>
            <v-card>
              <v-layout pt-2 wrap justify-start>
                <v-flex xs3 align-self-center>
                  <span class="tableSide" style="color: #606060"
                    >Vehicle Number</span
                  >
                </v-flex>
                <v-flex xs3 text-center>
                  <span class="tableSide" style="color: #606060">
                    Driver Name
                  </span>
                </v-flex>
                <v-flex xs2 text-center>
                  <span class="tableSide" style="color: #606060">
                    Seat Capacity
                  </span>
                </v-flex>
                <v-flex xs2 text-center>
                  <span class="tableSide" style="color: #606060"
                    >Driver Phone
                    <br />
                    Number
                  </span>
                </v-flex>
              </v-layout>
              <div>
                <v-layout
                  pl-2
                  v-for="(item, i) in packagedata"
                  :key="i"
                  wrap
                  justify-start
                >
                  <v-flex pl-4 xs3 align-self-center>
                    <v-text-field
                      solo
                      v-model="item.vehicleNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 pa-4 text-left>
                    <v-text-field
                      solo
                      v-model="item.vehicleDriver"
                    ></v-text-field>
                  </v-flex>
                  <v-flex pa-4 xs2 text-left>
                    <v-text-field
                      solo
                      v-model="item.seatCapacity"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 pa-4 text-left>
                    <v-text-field
                      solo
                      v-model="item.vehiclePhone"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 pt-6>
                    <v-btn
                      dark
                      style="background-color: #68d389"
                      dense
                      @click="packagedata.splice(i, 1)"
                    >
                      Remove
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-card>
          </v-flex>
          <v-flex xs12> </v-flex>
        </v-layout>

        <v-layout wrap justify-center pt-4>
          <v-flex xs12 hidden-md-and-up>
            <v-layout
              wrap
              justify-center
              pt-2
              v-if="programData.bookingAvailability"
            >
              <v-flex xs6 align-self-center class="pb-2">
                <span class="tableSide">Package Rate</span>
              </v-flex>
              <v-flex xs12>
                <v-flex xs12>
                  <span class="tableHeading"> Weekdays </span>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.indian">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Indian
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                v-if="programData.bookingAvailability.foreigner"
                xs12
                pt-1
              >
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreigner
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.children">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.foreigneChildren">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreign Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.foreignStudent"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout
              wrap
              justify-center
              pt-2
              v-if="programData.bookingAvailability"
            >
              <v-flex xs12>
                <v-flex xs12>
                  <span class="tableHeading">Weekends And Holidays</span>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.indian">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Indian
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                pt-1
                v-if="programData.bookingAvailability.foreigner"
              >
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreigner
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.children">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.foreigneChildren">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                       Foreign Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.foreignStudent"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start>
              <v-flex text-left xs10 md3>
                <span style="font-family: poppinssemibold; font-size: 14px"
                  >Do you want to add extra per head</span
                >
              </v-flex>
              <v-flex xs2 md1>
                <v-checkbox
                  v-model="packageRate.isExtraPerHeadAvailable"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              pt-2
              v-if="packageRate.isExtraPerHeadAvailable"
            >
              <v-flex xs12>
                <v-flex xs12>
                  <span class="tableHeading"> Extra per head</span>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.indian">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Indian
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                pt-1
                v-if="programData.bookingAvailability.foreigner"
              >
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreigner
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.children">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.foreigneChildren">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                       Foreign Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.foreignStudent"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex text-left>
            <span style="font-family: poppinssemibold; font-size: 14px">
              Please mention the various package facilities available
            </span>

            <v-combobox
              v-model="packageRate.packageFacility"
              :items="items"
              chips
              clearable
              label="Package Facilities"
              multiple
              solo
              class="pt-3"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-end>
          <v-flex md3 xs12>
            <v-btn
              block
              tile
              color="#68D389"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #ffffff">Save Changes</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-dialog v-model="dialog2" height="500px" max-width="1100px">
        <v-card class="pa-8">
          <v-layout wrap>
            <v-card flat>
              <!-- <v-layout wrap>
    <v-flex xs12 pt-1 v-for="(item, i) in vehicle" :key="i"> -->
              <v-layout wrap pt-2>
                <v-flex xs12 lg3 pt-8 pt-lg-0 pa-3>
                  <span class="tableSide" style="color: #606060"
                    >Vehicle Number</span
                  >
                  <v-text-field
                    v-model="item2.vehicleNumber"
                    dense
                    solo
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 lg2 pt-4 pt-lg-0 pa-3>
                  <span class="tableSide" style="color: #606060"
                    >Seat Capacity</span
                  >

                  <v-text-field
                    v-model="item2.seatCapacity"
                    dense
                    solo
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 lg2 pt-4 pt-lg-0 pa-3>
                  <span class="tableSide" style="color: #606060"
                    >Driver Name</span
                  >

                  <v-text-field
                    v-model="item2.vehicleDriver"
                    dense
                    type="text"
                    solo
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 lg3 pt-4 pt-lg-0 pa-3>
                  <span class="tableSide" style="color: #606060"
                    >Driver Phone Number</span
                  >
                  <v-text-field
                    v-model="item2.vehiclePhone"
                    dense
                    type="number"
                    solo
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 lg2 pt-6>
                  <v-btn
                    dark
                    style="background-color: #68d389"
                    dense
                    @click="addvehicle(), (dialog2 = false)"
                  >
                    ADD
                  </v-btn>
                </v-flex>
              </v-layout>
              <!-- </v-flex>
  </v-layout> -->
            </v-card>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["id", "progId"],
  data() {
    return {
      test: null,
      vehicle: [],
      dialog2: false,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      menu: false,
      singleitem: {
        vehicleDriver: null,
        vehicleNumber: null,
        seatCapacity: null,
        vehiclePhone: "",
      },
      modal: false,
      curid: [],
      dialog1: false,
      // isFixedRateAvailable: false,
      menu2: false,
      programId: null,
      isProgramAdded: true,
      item2: {
        vehicleNumber: "",
        seatCapacity: "",
        vehicleDriver: "",
        vehiclePhone: "",
      },
      items: [
        "Lunch",
        "Toilet",
        "Shopping Facility",
        "Drinking Water",
        "Information Center",
      ],
      programData: [],
      packageRate: {
        isProgram: true,
        program: null,
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),
        indian: null,
        children: null,
        foreignStudent:null,
        foreigner: null,
        isExtraPerHeadAvailable: false,
        // foreignStudent: null,
        // bonafiedStudent: null,
        discount: null,
        packageFacility: [],
        // weekend: {
        //   indian: null,
        //   children: null,
        //   foreigner: null,
        //   foreignStudent: null,
        //   bonafiedStudent: null,
        // },
        holidays: {
          indian: null,
          children: null,
          foreigner: null,
          // foreignStudent: null,
          // bonafiedStudent: null,
        },
        extraperhead: {
          indian: null,
          children: null,
          foreigner: null,
          foreignStudent:null,
          // foreignStudent: null,
          // bonafiedStudent: null,
        },
        packagerateid: null,
      },
      packagedata1: [],
      details: [],
      appLoading: false,
      ServerError: false,
      packagedata: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  beforeMount() {
    this.getData();
    this.getProgram();
  },

  mounted() {
    this.packageRate.isFixedrate = true;
  },
  watch: {
    isExtraPerHeadAvailable() {
      if (!this.packageRate.isExtraPerHeadAvailable) {
        Object.keys(this.packageRate.extraperhead).forEach(
          (key) => (this.packageRate.extraperhead[key] = null)
        );
      }
    },
  },
  methods: {
    addvehicle() {
      // this.vehicle.push(this.item2)
      this.singleitem.vehiclePhone = this.item2.vehiclePhone;
      this.singleitem.vehicleNumber = this.item2.vehicleNumber;
      this.singleitem.seatCapacity = this.item2.seatCapacity;
      this.singleitem.vehicleDriver = this.item2.vehicleDriver;
      this.packagedata.push(JSON.parse(JSON.stringify(this.singleitem)));
    },
    addVariant() {
      this.vehicle.push(JSON.parse(JSON.stringify(this.singleitem)));
      this.vehiclePhone = null;
      this.vehicleNumber = null;
      this.seatCapacity = null;
      this.vehicleDriver = null;
    },
    addValues() {
      {
        this.singleitem.vehiclePhone = this.vehiclePhone;
        this.singleitem.vehicleNumber = this.vehicleNumber;
        this.singleitem.seatCapacity = this.seatCapacity;
        this.singleitem.vehicleDriver = this.vehicleDriver;
        this.addVariant();
      }
    },
    validateInput() {
      // if (!this.packageRate.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else if (!this.packageRate.weekend.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.weekend.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.weekend.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else if (!this.packageRate.holidays.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.holidays.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.holidays.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.extraperhead.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.extraperhead.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.extraperhead.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else {
      //   this.editPackage();
      // }
      this.editPackage();
    },
    remove(item) {
      this.packageRate.packageFacility.splice(
        this.packageRate.packageFacility.indexOf(item),
        1
      );
      this.packageRate.packageFacility = [...this.packageRate.packageFacility];
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/packagerate/get",
        params: {
          id: this.id,
          //   date: new Date(),
        },
      })
        .then((response) => {
          this.packagedata = response.data.data.vehicle;
          this.packagedata1 = response.data.data.vehicle;
          this.appLoading = false;
          this.details = response.data.data;
          this.packageRate = this.details;
          this.packageRate.fromDate = this.packageRate.fromDate.slice(0, 10);
          this.packageRate.toDate = this.packageRate.toDate.slice(0, 10);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProgram() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/adminget",
        params: {
          id: this.progId,
          //   date: new Date(),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            if (response.data.package != null)
              this.programData = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editPackage() {
      // this.packageRate.vehicle = this.vehicle;
      this.packageRate.packagerateid = this.id;
      this.packageRate.program = this.progId;
      this.packageRate.vehicle = this.packagedata;
      axios({
        method: "post",
        url: "/edit/program/rate",
        data: this.packageRate,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Package Edited Successully";
            this.showSnackBar = true;

            this.$router.go(-1);
            // Object.keys(this.programs).forEach(
            //   (key) => (this.programs[key] = null)
            // );
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + ", " + year + " ";
      return strTime;
    },
  },
};
</script>